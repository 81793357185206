import PrimaryTitle from './PrimaryTitle'
import SecondaryButton from './SecondaryButton'

const LargeCard = ({ title, description, cta, ctaHref, imgSrc, imgAlt }: {
  title: string
  description: string | Array<string>
  cta: string
  ctaHref: string
  imgSrc?: string
  imgAlt?: string
}) => (
  <div>
    <div className="sm:hidden">
      <PrimaryTitle title={title} />
    </div>
    <div className="flex md:space-x-20 px-6 py-6 sm:py-14 md:px-16 bg-lightGray rounded-3xl flex-col-reverse md:flex-row md:items-start items-center">
      <div className="min-w-0">
        <div className="hidden sm:block text-2xl font-bold mb-6">{title}</div>
        <div className="mb-8 md:mb-16 mx-2 sm:mx-0">
          <div>
            {Array.isArray(description) ? <div className="flex flex-col space-y-4">{description.map((part, index) => (<div key={index}>{part}</div>))}</div> : description}
          </div>
        </div>
        <div className='flex flex-col sm:flex-row mx-2 sm:mx-0'>
          <SecondaryButton title={cta} href={ctaHref} fullWidth />
        </div>
      </div>

      <div className="flex-shrink-0">
        <img className="mb-6 md:mb-0 md:w-60 lg:w-80 lg-w-20" src={`${imgSrc || '/headset.png'}`} alt={`${imgAlt || 'VR headset'}`} />
      </div>
    </div>
  </div>
)

export default LargeCard
