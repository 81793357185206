import LargeCard from '../components/LargeCard'
import paths from '../path'


const project = {
    title:
        'Projekt neomento',
    description:
        ['Neomento ist ein Projekt von ExpertInnen für therapeutische AnwenderInnen mit dem Anspruch maximaler therapeutischer Wirksamkeit, Praxistauglichkeit und Qualität in Anbetracht aktueller wissenschaftlicher Studien.', 'Unser Team besteht aus PsychologInnen, WissenschaftlerInnen, Software-EntwicklerInnen und DesignerInnen. Gemeinsam orientieren wir uns an den Erfahrungen und Erkenntnissen moderner Psychotherapie und Therapieforschung und entwickeln in einem deutschlandweiten Projekt virtuelle Erlebnisse, welche auf den Bedarf von Therapeuten und die Bedürfnisse von PatientInnen zugeschnitten sind.'],
    cta: 'Mehr erfahren',
    ctaHref: paths.about,
    imgSrc: '/chariteSkylineSquare.jpeg',
    imgAlt: 'charite skyline',
}

const ProjectNeomentoCard = () => <LargeCard
    title={project.title}
    description={project.description}
    cta={project.cta}
    ctaHref={project.ctaHref}
    imgSrc={project.imgSrc}
    imgAlt={project.imgAlt}
/>

export default ProjectNeomentoCard