import PrimaryTitle from './PrimaryTitle'

const title = 'Unterstützt durch:'

const Support = () => (
  <>
    <PrimaryTitle title={title} center />
    <div className="flex flex-wrap flex-row gap-x-8 gap-y-8 justify-center mt-12">
      <div>
        <img className="h-10 sm:h-20" src="/support/bmwi.svg" />
      </div>
      <div>
        <img className="h-10 sm:h-20" src="/support/dzne.svg" />
      </div>
      <div>
        <img className="h-10 sm:h-20" src="/support/helmholtz.svg" />
      </div>
      <div>
        <img className="h-10 sm:h-20" src="/support/erc.svg" />
      </div>
      <div>
        <img className="h-10 sm:h-20" src="/support/esf.svg" />
      </div>
      <div>
        <img className="h-10 sm:h-20" src="/support/exist.svg" />
      </div>
    </div>
  </>
)

export default Support
