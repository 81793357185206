import Page from '../components/Page'
import LargeCard from '../components/LargeCard'
import Section from '../components/Section'
import Support from '../components/Support'
import PrimaryTitle from '../components/PrimaryTitle'
import TinyLink from '../components/TinyLink'
import VRScene from '../components/VRScene'
import ProjectNeomentoCard from '../components/ProjectNeomentoCard'
import Model from '../components/Model'
import paths from '../path'

const pageTitle = 'neomento Virtual-Reality-Therapie'

const hero = {
  title: 'Virtual-Reality-Therapie',
  description:
    'Schaffen Sie mit einem Knopfdruck therapeutisch wirksame Räume und Situationen. Neomento ermöglicht es Ihnen, in Ihrer Praxis schnell, kontrolliert und exakt auf die Bedürfnisse Ihrer PatientInnen zugeschnittene Expositionsübungen und Trainings durchzuführen.',
  cta: 'Mehr zur Technologie >',
  ctaHref: paths.therapy,
}

const treatment = {
  title: 'Virtual Reality im Behandlungszimmer',
  description:
    'Gemeinsam mit unserem Hardwarepartner Schenker Technologies stellen wir Ihnen ein besonders auf die Anforderungen im klinischen Alltag zugeschnittenes, hochwertiges VR-Setup zur Verfügung. Zudem schulen wir TherapeutInnen im Umgang mit Hardware und Software.',
  cta: 'Mehr zur Therapie',
  ctaHref: paths.therapy,
  imgSrc: '/headsetOnDesk.png',
  imgAlt: 'headset on desk',
}

const reality = {
  title: 'Die virtuelle Realität von neomento',
  description:
    'All unsere Szenarien stellen mit hohem Detailgrad lebensechte Orte und Situationen nach. Die Fülle an Eindrücken alltäglicher Situationen wird in virtuellen Modellen nachgebaut und in VR besonders lebensnah simuliert. Eine Fahrt mit der U-Bahn, ein Vortragsraum und viele andere Situationen lassen sich patientenorientiert auswählen und anpassen. Die in die Situationen eingebetteten Angstreize, aber auch deren Erscheinungsbild selbst, können Sie in VR beliebig aufbauen, kombinieren und steuern.',
  cta: 'Erfahren Sie mehr über unsere Szenarien >',
  ctaHref: paths.product,
}

const Home = () => (
  <Page title={pageTitle}>
    <Section>
      <div className="flex sm:space-x-20 flex-col-reverse sm:flex-row sm:items-start">
        <div className="flex-1">
          <div className='hidden sm:block'>
            <PrimaryTitle title={hero.title} />
          </div>
          <p className="pt-2 text-lg sm:text-2xl max-w-4xl">{hero.description}</p>
        </div>
        <div className="w-full sm:w-auto flex-shrink-0 mb-8 sm:mb-0 self-center">
          <div className='mb-9 max-w-full w-96 h-96'>
            <Model />
          </div>
          <TinyLink center href={hero.ctaHref}>{hero.cta}</TinyLink>
        </div>
        <div className='sm:hidden'>
          <PrimaryTitle title={hero.title} />
        </div>
      </div>
    </Section>
    <Section>
      <LargeCard
        title={treatment.title}
        description={treatment.description}
        cta={treatment.cta}
        ctaHref={treatment.ctaHref}
        imgSrc={treatment.imgSrc}
        imgAlt={treatment.imgAlt}
      />
    </Section>
    <Section>
      <PrimaryTitle title={reality.title} center />
      <p className="max-w-2xl items-center ml-auto mr-auto mb-9">
        {reality.description}
      </p>
      <TinyLink center href={reality.ctaHref}>{reality.cta}</TinyLink>
    </Section>
    <div className='mt-2 sm:-mt-8'>
      <VRScene />
    </div>
    <Section>
      <ProjectNeomentoCard />
    </Section>
    <Section>
      <Support />
    </Section>
  </Page>
)

export default Home
