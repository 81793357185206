const PrimaryTitle = ({
  title,
  center,
  mainColor,
  withTopMargin,
  hero
}: {
  title: string
  center?: boolean
  mainColor?: boolean
  withTopMargin?: boolean
  hero?: boolean
}) => (
  hero ? (
    <h1
      className="font-bold text-3xl sm:text-4xl mb-6 leading-tight">
      {title}
    </h1>
  ) : (
    < h1
      className={`leading-5 font-bold text-lg sm:text-3xl sm:leading-8 mb-6 ${center ? 'text-center' : ''} ${withTopMargin ? 'mt-28' : ''
        } ${mainColor ? 'text-main' : 'text-blackGray'}`}
    >
      {title}
    </h1 >
  )
)

export default PrimaryTitle
