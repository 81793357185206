import { useState } from "react";
import ReactPannellum from "react-pannellum";

const vrScene = {
    shortDescription: 'Hier sehen sie ein Beispiel einer unserer virtuellen Szenen, anhand eines öffentlichen...',
    description:
        'Hier sehen Sie ein Beispiel einer unserer virtuellen Szenen, anhand eines öffentlichen Platzes: Sie können unter anderem steuern, wie viele Personen sich auf dem Platz bewegen, ob und wie Personen Ihre PatientInnen ansprechen, wie laut die Umgebungsgeräusche sind, Stimmungen simulieren und spezielle Geräuschkulissen erzeugen.',
    cta: 'mehr lesen >',
}

const VRScene = () => {
    const [expanded, setExpanded] = useState(false)
    const onExpand = () => setExpanded(true)

    return (
        <div className="flex justify-center">
            <div className="relative mb-6 md:mb-0 w-full h-96 sm:h-700px">
                <ReactPannellum
                    id="1"
                    sceneId="firstScene"
                    imageSource="/scenes/square.jpg"
                    config={{
                        autoRotate: -2,
                        showControls: false,
                        preview: "/square.png",
                        autoLoad: true,
                        uiText: {
                            loadingLabel: ''
                        },
                        keyboardZoom: false,
                        mouseZoom: false,
                        doubleClickZoom: false
                    }}
                    style={{ width: '100%', height: '100%' }}
                />

                <div className="absolute bg-white bottom-0 left-0 mx-6 sm:mx-12 my-12 rounded-3xl opacity-80 xl:w-4/12 lg:w-6/12 md:w-6/12 sm:w-8/12">
                    <div className="px-7 sm:px-6 py-6 sm:py-4 text-xs sm:text-sm">
                        <div className="hidden sm:block"><p>{vrScene.description}</p></div>
                        <div className="block sm:hidden">
                            {expanded ? (
                                <p>{vrScene.description}</p>
                            ) : (
                                <>
                                    <p>{vrScene.shortDescription}</p>
                                    <br />
                                    <p className="text-main hover:underline" onClick={onExpand}>
                                        {vrScene.cta}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VRScene