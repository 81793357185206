import Link from '../components/Link'

const TinyLink = ({ children, href, center }: {
    children: any
    href: string
    center?: boolean
}) => (
    <div className={`${center ? 'text-center ' : ''}text-main text-sm`}>
        <Link href={href}>{children}</Link>
    </div>
)

export default TinyLink