import Link from './Link'

const SecondaryButton = ({
  title,
  href,
  type,
  fullWidth,
  skipPadding,
}: {
  title: string
  href?: string
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  skipPadding?: boolean
}) => {
  const Button = (
    <button
      className={`hover:underline font-normal text-sm py-3 rounded-full text-grayDark border border-grayDark cursor-pointer ${fullWidth ? 'w-full' : ''
        } ${skipPadding ? 'px-4' : 'px-10 lg:px-16'} `}
      type={type || 'button'}
    >
      {title}
    </button>
  )

  return href ? <Link href={href}>{Button}</Link> : Button
}

export default SecondaryButton
